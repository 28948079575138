<template>
  <div class="userList">
    <!-- <searchBar :formItems="formItems" @search="search" /> -->
    <div style="margin-bottom: 10px">
      <el-button type="primary" @click="add()">
        <i class="el-icon-plus" />新增
      </el-button>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" :cell-style="{ textAlign: 'center' }" :header-cell-style="{ textAlign: 'center' }"
        border style="width: 100%">
        <el-table-column type="index" :index="indexMethod" label="序号" width="80" header-align="center" align="center">
        </el-table-column>
        <el-table-column prop="courseTitle" label="标题"></el-table-column>
        <el-table-column prop="headImg" label="静态封面">
          <template slot-scope="scope">
            <img :src="scope.row.headImg" alt="" style="width:200px;height:auto;"></template>
        </el-table-column>
        <el-table-column prop="videoUrl" label="视频地址">
          <template slot-scope="scope">
            <video :src="scope.row.videoUrl" alt="" style="width:300px;height:100px" controls></video>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
          <template slot-scope="scope">{{
            scope.row.createTime | formatTime("YMDHMS")
          }}</template>
        </el-table-column>
        <el-table-column prop="courseDescribe" label="课程描述"></el-table-column>
        <el-table-column prop label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editData(scope.row)">编辑</el-button>
            <el-button @click="deleteData(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page.page" :page-sizes="[5, 10, 20, 30, 50]" :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
    <UserDialog ref="dialog" @search="initPage" />
  </div>
</template>

<script>
// import searchBar from "@/components/SearchBar/index";
import UserDialog from "./components/dialog.vue";
export default {
  components: {  UserDialog },
  data() {
    return {
      page: {
        page: 1,
        pageSize: 5,
      },
      params: {},
      total: 0,
      tableData: [],
      formItems: [
        {
          label: "",
          type: "el-input",
          fieldName: "courseTitle",
          attrs: {
            placeholder: "课程名称",
          },
        },
      ],
    };
  },
  methods: {
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/admin/sys/main/course/list",
        { ...this.params, ...this.page },
        "get"
      ).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
    },
    editData(item) {
      this.$refs.dialog.getInfo(item);
    },
    deleteData(item) {
      this.$confirm("确定删除吗？").then(() => {
        this.getAjax("api/admin/sys/main/delCourse?doctorId=" + item.courseId, {}, "post").then((res) => {
          this.$message.success("删除成功");
          this.initPage();
        });
      });
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fff;

  .leftBox {
    width: 220px;
    height: 100%;
    background: #545c64;
  }

  .rightBox {
    flex: 1;
    padding: 20px;

    .fenye {
      text-align: right;
      padding: 15px 10px;
    }
  }
}
</style>
