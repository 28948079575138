<template>
  <div class="activityDialog">
    <el-dialog top="3%" width="50%" :title="dialogTitle" :visible.sync="visible" :close-on-click-modal="false"
      :before-close="closeEvent">
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="课程名称" prop="courseTitle">
              <el-input v-model="form.courseTitle" placeholder="请输入课程名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="课程图片" prop="headImg">
              <el-upload class="avatar-uploader" :headers="headers" :action="'/api/admin/public/uploadImg/course'"
                :show-file-list="false" :on-success="handleAvatarSuccess" :data="{
                  width: 750
                }" :before-upload="beforeAvatarUpload">
                <img v-if="form.headImg" :src="form.headImg" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="课程视频" prop="headImg">
              <el-upload class="avatar-uploader" :headers="headers" :action="'/api/admin/public/upload/course'"
                :show-file-list="false" :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload2">
                <video v-if="form.videoUrl" :src="form.videoUrl" class="avatar" controls style="width:100%" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="课程描述" prop="courseDescribe">
              <el-input type="textarea" :rows="4" placeholder="请输入课程描述" v-model="form.courseDescribe">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/assets/api/config.js";
export default {
  data() {
    return {
      visible: false,
      dialogTitle: "新增活动",
      statusList: [
        {
          label: "未开始",
          value: "0",
        },
        {
          label: "进行中",
          value: "1",
        },
        {
          label: "已结束",
          value: "2",
        },
      ],
      form: {
        courseTitle: "",
        headImg: "",
        courseDescribe: "",
        videoUrl: ""
      },
      headers: {
        token: localStorage.getItem('token')
      },
      config,
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.form.headImg = res.data.downloadUrl;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccess2(res, file) {
      console.log(res, file);
      this.form.videoUrl = res.data.downloadUrl;
    },
    beforeAvatarUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;

      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 500MB!");
      }
      return isLt2M;
    },
    submitData() {
      let params = JSON.parse(JSON.stringify(this.form));
      let url = "";
      if (params.courseId) {
        //修改
        url = "api/admin/sys/main/updateCourse";
      } else {
        url = "api/admin/sys/main/addCourse";
      }

      this.getAjax(url, params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增课程";
      this.form = {};
    },
    getInfo(info) {
      this.dialogTitle = "编辑课程";
      this.form = info;
      this.visible = true;
    },
  },
  mounted() { },
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
